define("knowme-client/models/business-profile", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "knowme-client/mixins/models/validations/business-profile", "knowme-client/mixins/models/ember-cp-validations-custom-properties", "knowme-client/mixins/dirty"], function (exports, _model, _attr, _relationships, _businessProfile, _emberCpValidationsCustomProperties, _dirty) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BusinessProfile;

  BusinessProfile = _model.default.extend(_businessProfile.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    // Relationships
    account: (0, _relationships.belongsTo)("account", { async: true }),
    // Attributes
    logoUrl: (0, _attr.default)("string"),
    logoThumbnailUrl: (0, _attr.default)("string"),
    logoSmallUrl: (0, _attr.default)("string"),
    logo: (0, _attr.default)(),
    name: (0, _attr.default)(),
    description: (0, _attr.default)(),
    email: (0, _attr.default)(),
    website: (0, _attr.default)(),
    category: (0, _attr.default)(),
    interestCategories: (0, _attr.default)(),
    tags: (0, _attr.default)(),
    phoneNumber: (0, _attr.default)(),
    addressCountry: (0, _attr.default)(),
    addressCity: (0, _attr.default)(),
    addressState: (0, _attr.default)(),
    addressStreet: (0, _attr.default)(),
    addressZip: (0, _attr.default)(),
    workTimes: (0, _attr.default)(),
    reviewLinksIds: (0, _attr.default)(),
    createdAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date")
  });

  exports.default = BusinessProfile;
});