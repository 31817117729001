define("knowme-client/controllers/account/settings/business-profile/name", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    businessProfileController: Ember.inject.controller("account.settings.business-profile"),
    model: Ember.computed.alias("businessProfileController.model"),
    disableSubmit: Ember.computed("model.validations.attrs.name.isInvalid", function () {
      return this.get("model.validations.attrs.name.isInvalid");
    }),
    uploadButtonName: Ember.computed("logoUrl", function () {
      if (Ember.isPresent(this.get("logoUrl"))) {
        return "Change logo";
      } else {
        return "Add logo";
      }
    }),
    logoUrl: Ember.computed("base64Data", "model.isNew", "model.logoUrl", function () {
      // if (this.get("model.isNew")) {
      //   return this.get("base64Data");
      // } else {
      //   return this.get("model.logoUrl");
      // }
      if (this.get("model.logoUrl")) {
        return this.get("model.logoUrl");
      } else {
        return this.get("base64Data");
      }
    }),
    actions: {
      addLogo: function (blob) {
        if (this.get("model.isNew")) {
          this.get("model").set("logo", blob.signedId);
          let reader = new FileReader();
          let file = blob.file;
          reader.onload = function (e) {
            this.set("base64Data", e.target.result);
          }.bind(this);

          reader.readAsDataURL(file);
        } else {
          this.get("model").set("logo", blob.signedId);
          this.get("model").save().then(function () {
            this.set("base64Data", null);
          }.bind(this));
        }
      },
      back: function () {
        this.get("router").transitionTo("dashboard");
      },
      submit: function () {
        this.get("model").save().then(function () {
          this.set("base64Data", null);
          this.get("router").transitionTo("account.settings.business-profile.email");
        }.bind(this));
      }
    }
  });
});