define("knowme-client/controllers/account/settings/business-profile/index", ["exports", "lodash", "knowme-client/mixins/us-states"], function (exports, _lodash, _usStates) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  const WORK_TIMES_FIELDS = ["model.workTimes.mon.open", "model.workTimes.mon.from.h", "model.workTimes.mon.from.m", "model.workTimes.mon.from.ampm", "model.workTimes.mon.to.h", "model.workTimes.mon.to.m", "model.workTimes.mon.to.ampm", "model.workTimes.tue.open", "model.workTimes.tue.from.h", "model.workTimes.tue.from.m", "model.workTimes.tue.from.ampm", "model.workTimes.tue.to.h", "model.workTimes.tue.to.m", "model.workTimes.tue.to.ampm", "model.workTimes.wed.open", "model.workTimes.wed.from.h", "model.workTimes.wed.from.m", "model.workTimes.wed.from.ampm", "model.workTimes.wed.to.h", "model.workTimes.wed.to.m", "model.workTimes.wed.to.ampm", "model.workTimes.thu.open", "model.workTimes.thu.from.h", "model.workTimes.thu.from.m", "model.workTimes.thu.from.ampm", "model.workTimes.thu.to.h", "model.workTimes.thu.to.m", "model.workTimes.thu.to.ampm", "model.workTimes.fri.open", "model.workTimes.fri.from.h", "model.workTimes.fri.from.m", "model.workTimes.fri.from.ampm", "model.workTimes.fri.to.h", "model.workTimes.fri.to.m", "model.workTimes.fri.to.ampm", "model.workTimes.sat.open", "model.workTimes.sat.from.h", "model.workTimes.sat.from.m", "model.workTimes.sat.from.ampm", "model.workTimes.sat.to.h", "model.workTimes.sat.to.m", "model.workTimes.sat.to.ampm", "model.workTimes.sun.open", "model.workTimes.sun.from.h", "model.workTimes.sun.from.m", "model.workTimes.sun.from.ampm", "model.workTimes.sun.to.h", "model.workTimes.sun.to.m", "model.workTimes.sun.to.ampm"];

  exports.default = Ember.Controller.extend(_usStates.default, {
    businessProfileController: Ember.inject.controller("account.settings.business-profile"),
    model: Ember.computed.alias("businessProfileController.model"),
    stripe: Ember.inject.service("stripe"),
    isAddPaymentPressed: false,
    step: 1,
    stripeLoad: function () {
      this.get("stripe").load();
    },
    init: function () {
      this._super(...arguments);
      this.set("step", 1);
      this.set("isAddPaymentPressed", false);
    },
    uploadButtonName: Ember.computed("logoUrl", function () {
      if (Ember.isPresent(this.get("logoUrl"))) {
        return "Change logo";
      } else {
        return "Add logo";
      }
    }),
    logoUrl: Ember.computed("base64Data", "model.isNew", "model.logoUrl", function () {
      // if (this.get("model.isNew")) {
      //   return this.get("base64Data");
      // } else {
      //   return this.get("model.logoUrl");
      // }
      if (this.get("model.logoUrl")) {
        return this.get("model.logoUrl");
      } else {
        return this.get("base64Data");
      }
    }),
    initWorkTimes: function () {
      if (this.get("model.isNew") || Ember.isBlank(this.get("model.workTimes")) || Object.keys(this.get("model.workTimes")).length === 0) {
        this.set("model.workTimes", this.get("defaultWorkTimes"));
      }
    },
    daysOfWeek: [{ key: "mon", title: "Mon" }, { key: "tue", title: "Tue" }, { key: "wed", title: "Wed" }, { key: "thu", title: "Thu" }, { key: "fri", title: "Fri" }, { key: "sat", title: "Sat" }, { key: "sun", title: "Sun" }],
    defaultWorkTimes: {
      mon: {
        open: true,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      },
      tue: {
        open: true,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      },
      wed: {
        open: true,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      },
      thu: {
        open: true,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      },
      fri: {
        open: true,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      },
      sat: {
        open: false,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      },
      sun: {
        open: false,
        from: { h: "09", m: "00", ampm: "AM" },
        to: { h: "06", m: "00", ampm: "PM" }
      }
    },
    hours: Array.apply(null, Array(12)).map(function (v, i) {
      if (i < 9) {
        return "0" + (i + 1);
      } else {
        return i + 1;
      }
    }),
    minutes: Array.apply(null, Array(12)).map(function (v, i) {
      if (i < 2) {
        return "0" + i * 5;
      } else {
        return i * 5;
      }
    }),
    reviewWebSiteOptions: [{ id: "google-business", name: "Google My Business" }, { id: "amazon", name: "Amazon" }, { id: "facebook", name: "Facebook" }, { id: "yelp", name: "Yelp" }, { id: "trip-advisor", name: "Trip Advisor" }, { id: "bbb", name: "BBB (Better Business Bureau)" }, { id: "yellowpages", name: "Yellowpages" }, { id: "manta", name: "Manta" }, { id: "angies-list", name: "Angies List" }, { id: "foursquare", name: "Foursquare" }, { id: "my-website", name: "My Website (via Knowme IQ Review)" }, { id: "custom", name: "Custom" }],
    amPm: ["AM", "PM"],
    states: [{ id: "", name: "Select Country" }],
    statePromt: "State",
    codeLabel: "Zip",
    onCountrySelect: Ember.observer("model.addressCountry", function () {
      this.setStatesList();
    }),
    setStatesList: function () {
      if (Ember.isPresent(this.get("model.addressCountry"))) {
        if (this.get("model.addressCountry") === "us") {
          this.set("states", this.get("usStates"));
          this.set("statePromt", "State");
          this.set("codeLabel", "Zip");
        } else {
          this.set("states", this.get("canadianProvinces"));
          this.set("statePromt", "Province");
          this.set("codeLabel", "Postal");
        }
        if (!this.get("states").mapBy("id").includes(this.get("model.addressState"))) {
          this.set("model.addressState", null);
        } else {
          let addressState = _lodash.default.find(this.get("states"), {
            id: this.get("model.addressState")
          });
          this.set("addressState", addressState);
        }
      } else {
        this.set("states", [{ id: "", name: "Select Country" }]);
      }
    },
    category: Ember.computed("model.category", function () {
      if (this.get("model.category")) {
        let category = _lodash.default.find(this.get("categories"), {
          id: this.get("model.category")
        });
        return category;
      }
    }),
    profileReviewLinks: Ember.computed("model.reviewLinksIds", "reviewLinks.length", function () {
      let arr = [];
      this.get("reviewLinks").forEach(function (reviewLink) {
        if (this.get("model.reviewLinksIds").includes(reviewLink.id)) {
          arr.push(reviewLink);
        }
      }.bind(this));

      return arr;
    }),
    hasWorkTimesChanged: Ember.computed(...WORK_TIMES_FIELDS, "savedWorkTimes", function () {
      let hasChanged = false;
      WORK_TIMES_FIELDS.forEach(function (field) {
        let parts = field.split(".");
        parts.shift();
        parts.shift();
        let key = parts.join(".");

        hasChanged = hasChanged || this.get(`savedWorkTimes.${key}`) != this.get(field);
      }.bind(this));
      return hasChanged;
    }),
    disableSubmit: Ember.computed("step", "model.hasDirtyAttributes", "model.validations.attrs.name.isValid", "model.validations.attrs.description.isValid", "model.validations.attrs.email.isValid", "model.validations.attrs.phoneNumber.isValid", "model.validations.attrs.addressCountry.isValid", "model.validations.attrs.addressCity.isValid", "model.validations.attrs.addressState.isValid", "model.validations.attrs.addressStreet.isValid", "model.validations.isInvalid", "model.hasDirtyAttributes", "hasWorkTimesChanged", "reviewLink.validations.isInvalid", "reviewLink.hasDirtyAttributes", function () {
      if (this.get("step") === 1) {
        const fields = ["name", "description", "email", "phoneNumber", "addressCountry", "addressCity", "addressState", "addressStreet"];

        let fieldsAreValid = true;
        for (let field of fields) {
          fieldsAreValid = fieldsAreValid && this.get(`model.validations.attrs.${field}.isValid`);
        }

        return !(this.get("model.hasDirtyAttributes") && fieldsAreValid);
      } else {
        let enable = this.get("model.hasDirtyAttributes") && this.get("model.validations.isValid") || this.get("hasWorkTimesChanged") || this.get("reviewLink.validations.isValid") && this.get("reviewLink.isNew");
        return !enable;
      }
    }),

    addPaymentMethodDisabled: Ember.computed("isAddPaymentPressed", "model.description", "model.descriptionHasChanged", "model.hasDirtyAttributes", function () {
      let addPaymentMethodEnabled = false;
      if (this.get("model").changedAttributes().description) {
        addPaymentMethodEnabled = !!this.get("model").changedAttributes().description[0];
      } else {
        addPaymentMethodEnabled = !!this.get("model.description");
      }

      return this.get("isAddPaymentPressed") || !addPaymentMethodEnabled;
    }),
    actions: {
      addLogo: function (blob) {
        if (this.get("model.isNew")) {
          this.get("model").set("logo", blob.signedId);
          let reader = new FileReader();
          let file = blob.file;
          reader.onload = function (e) {
            this.set("base64Data", e.target.result);
          }.bind(this);

          reader.readAsDataURL(file);
        } else {
          this.get("model").set("logo", blob.signedId);
          this.get("model").save().then(function () {
            this.set("base64Data", null);
          }.bind(this));
        }
      },
      createTagOnEnter: function (object, evt) {
        if (evt.keyCode === 13 && object.results.length === 0) {
          let option = {
            id: object.lastSearchedText.toLocaleLowerCase().replace(/\s/g, "_"),
            name: object.lastSearchedText
          };
          object.selected.push(option);
          this.get("tags").push(option);
          const tags = this.get("model.tags");
          tags.push(option);
          // this.get("model.tags").push(option);
          this.set("model.tags", tags.map(x => x));
        }
      },
      createOnEnter: function (object, evt) {
        if (evt.keyCode === 13 && object.results.length === 0) {
          let option = {
            id: object.lastSearchedText.toLocaleLowerCase().replace(/\s/g, "_"),
            name: object.lastSearchedText
          };
          object.selected.push(option);
          this.get("categories").push(option);
          const interestCategories = this.get("model.interestCategories");
          interestCategories.push(option);
          // this.get("model.interestCategories").push(option);
          this.set("model.interestCategories", interestCategories.map(x => x));
        }
      },
      createCustomCategory: function (object, evt) {
        if (evt.keyCode === 13 && object.results.length === 0) {
          let value = object.lastSearchedText.toLocaleLowerCase().replace(/\s/g, "_");
          let option = {
            id: value,
            name: object.lastSearchedText
          };
          let categories = this.get("categories");
          categories.push(option);
          this.set("categories", Ember.copy(categories, true));
          Ember.set(object, "selected", option);
          this.set("model.category", value);
        }
      },
      addPaymentMethod: function () {
        this.set("isAddPaymentPressed", true);
        let stripeAccount = this.get("store").createRecord("stripe-account");
        stripeAccount.save().then(function (sa) {
          window.open(sa.get("accountLink"), "_self").focus();
        });
      },
      toggleDay: function (day) {
        this.set(`model.workTimes.${day}.open`, !this.get(`model.workTimes.${day}.open`));
      },
      setState: function (addressState) {
        this.set("addressState", addressState);
        this.set("model.addressState", addressState.id);
      },
      setCategory: function (category) {
        this.set("model.category", category.id);
      },
      toggleReviewLink: function (reviewLinkId) {
        const reviewLinksIds = this.get("model.reviewLinksIds") || [];
        const index = reviewLinksIds.indexOf(reviewLinkId);

        if (index > -1) {
          reviewLinksIds.splice(index, 1);
        } else {
          reviewLinksIds.push(reviewLinkId);
        }
        this.set("model.reviewLinksIds", reviewLinksIds.map(x => x));
      },
      back: function () {
        if (this.get("step") === 1) {
          this.set("editMode", false);
          this.get("model").rollbackAttributes();
        } else {
          this.set("step", 1);
        }
      },
      next: function () {
        this.set("step", 2);
      },
      exit: function () {
        this.set("step", 1);
        this.set("editMode", false);
        this.get("model").rollbackAttributes();
      },
      submit: function () {
        if (Ember.isPresent(this.get("reviewLink")) && this.get("reviewLink.isNew") && this.get("reviewLink.validations.isValid")) {
          this.get("reviewLink").save().then(function (reviewLink) {
            this.send("toggleReviewLink", reviewLink.get("id"));

            this.get("model").save().then(function () {
              this.get("target._routerMicrolib").refresh();
            }.bind(this));
          }.bind(this));
        } else {
          this.get("model").save().then(function (bp) {
            this.set("savedWorkTimes", Ember.copy(bp.get("workTimes"), true));
          }.bind(this));
        }
      }
    }
  });
});