define("knowme-client/components/products/resource-form", ["exports", "knowme-client/mixins/controllers/product-types", "knowme-client/mixins/controllers/status", "knowme-client/mixins/controllers/product-photo"], function (exports, _productTypes, _status, _productPhoto) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_status.default, _productTypes.default, _productPhoto.default, {
    router: Ember.inject.service(),
    routing: Ember.inject.service("-routing"),
    features: Ember.inject.service(),
    session: Ember.inject.service(),
    product: Ember.computed.alias("model"),
    publishResources: [],
    percentageMaskOptions: { suffix: " %" },
    onTaxableChange: Ember.observer("model.taxable", function () {
      if (!this.get("model.isNew")) {
        return;
      }
      if (this.get("model.taxable")) {
        if (Ember.isPresent(this.get("session.currentUser.account.activeSettings.taxRate"))) {
          this.set("model.taxRate", this.get("session.currentUser.account.activeSettings.taxRate"));
        }
      } else {
        this.set("model.taxRate", null);
      }
    }),
    displayInventoryField: Ember.computed("model.kind", function () {
      return ["product", "bundle"].includes(this.get("model.kind"));
    }),
    actions: {
      submit: function (step) {
        let controller = this;
        let resource = controller.get("model");

        // if (resource._internalModel) {
        // internalModel.prototype.setId does not work for product loaded on opportunity page
        resource._internalModel.setId = function setId(id) {
          true && !(this.id === null || this.id === id || this.isNew()) && assert("A record's id cannot be changed once it is in the loaded state", this.id === null || this.id === id || this.isNew());

          Ember.set(this, "id", id);
          if (this._record.get("id") !== id) {
            this._record.set("id", id);
          }
        };
        // }

        if (controller.get("model.isNew")) {
          controller.get("model").set("files", this.get("productPhotos"));
        }
        resource.save().then(function (product) {
          controller.set("base64Data", null);
          controller.set("unsavedPhotos", []);
          if (controller.get("isDealForm")) {} else {
            if (step === "close") {
              controller.get("router").transitionTo("account.settings.products", {
                queryParams: { status: product.get("status") }
              });
            }
            controller.get("router._router._routerMicrolib").refresh();
          }
        }).catch(function (reason) {
          console.log("Errors", reason);
        });
      },
      clear: function () {
        this.get("model").rollbackAttributes();
        this.transitionToRoute("account.settings.products");
      }
    },
    isModelTreeDirty: Ember.computed("model.hasDirtyAttributes", function () {
      return this.get("model.hasDirtyAttributes");
    }),
    disableSubmit: Ember.computed("model", "model.validations.isValid", "isModelTreeDirty", function () {
      return this.get("model.validations.isInvalid") || !this.get("isModelTreeDirty");
    }),
    disableStatusOptions: Ember.computed("model", function () {
      return this.get("model.isSoldOut");
    }),
    taxCodeOptions: [{ value: true, name: "Taxable" }, { value: false, name: "Non-Taxable" }]
  });
});