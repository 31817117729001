define("knowme-client/models/sales-transaction", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "knowme-client/mixins/dirty"], function (exports, _model, _attr, _relationships, _dirty) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Invoice;

  Invoice = _model.default.extend(_dirty.default, {
    // Relationships
    user: (0, _relationships.belongsTo)("user", { async: true }),
    account: (0, _relationships.belongsTo)("account", { async: true }),
    opportunity: (0, _relationships.belongsTo)("opportunity", {
      async: true,
      inverse: "salesTransactions"
    }),
    contact: (0, _relationships.belongsTo)("contact", { async: true, inverse: "salesTransactions" }),
    salesTransactionTarget: (0, _relationships.belongsTo)("sales-transaction-target", {
      async: true,
      polymorphic: true,
      inverse: "salesTransactions"
    }),
    salesTransactionTargetType: Ember.computed("salesTransactionTarget", function () {
      if (this.get("salesTransactionTarget.content")) {
        return this.get("salesTransactionTarget.content").constructor.modelName.camelize().capitalize();
      } else {
        if (this.get("salesTransactionTarget").constructor.modelName) {
          return this.get("salesTransactionTarget").constructor.modelName.camelize().capitalize();
        } else {
          return null;
        }
      }
    }),
    // Attributes
    agreement: (0, _attr.default)(),
    type: (0, _attr.default)(),
    note: (0, _attr.default)(),
    status: (0, _attr.default)(),
    source: (0, _attr.default)(),
    paymentId: (0, _attr.default)(),
    isEstimate: Ember.computed.equal("type", "Estimate"),
    idNumber: (0, _attr.default)(),
    isDraft: Ember.computed.equal("status", "draft"),
    isAccepted: Ember.computed.equal("status", "accepted"),
    isSent: Ember.computed.equal("status", "sent"),
    isPaid: Ember.computed.equal("status", "paid"),
    isPaymentFailed: Ember.computed.equal("status", "payment_failed"),
    createdAt: (0, _attr.default)("date"),
    paidAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date")
  });

  exports.default = Invoice;
});