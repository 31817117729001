define("knowme-client/models/review-link", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "knowme-client/mixins/models/validations/review-link", "knowme-client/mixins/models/ember-cp-validations-custom-properties", "knowme-client/mixins/dirty"], function (exports, _model, _attr, _relationships, _reviewLink, _emberCpValidationsCustomProperties, _dirty) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Review;

  Review = _model.default.extend(_reviewLink.default, _emberCpValidationsCustomProperties.default, _dirty.default, {
    // Relationships
    account: (0, _relationships.belongsTo)("account", { async: true }),
    reviewLinksContacts: (0, _relationships.hasMany)("review-links-contact", {
      async: true,
      inverse: "reviewLink"
    }),
    // Attributes
    name: (0, _attr.default)(),
    url: (0, _attr.default)(),
    normalizedURL: Ember.computed("url", function () {
      let url = this.get("url").trim();
      if (!/^https?:\/\//i.test(url)) {
        return `https://${url}`;
      }
      return url;
    }),
    status: (0, _attr.default)("string", { defaultValue: "active" }),
    default: (0, _attr.default)("boolean", { defaultValue: false }),
    sentCount: (0, _attr.default)("number", { default: 0 }),
    openedCount: (0, _attr.default)("number", { default: 0 }),
    badResponseCount: (0, _attr.default)("number"),
    goodResponseCount: (0, _attr.default)("number"),
    createdAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date")
  });

  exports.default = Review;
});