define("knowme-client/components/deals/resource-form", ["exports", "knowme-client/mixins/controllers/status", "ember-uploader/uploaders"], function (exports, _status, _uploaders) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_status.default, {
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    disableSubmit: Ember.computed("model", "model.validations.isValid", "model.hasDirtyAttributes", "maxDiscountExceeded", function () {
      return this.get("model.validations.isInvalid") || !this.get("model.hasDirtyAttributes") || this.get("maxDiscountExceeded");
    }),
    disableEdit: Ember.computed("model.isNew", function () {
      return !this.get("model.isNew");
    }),
    onNameEnter: Ember.observer("model.title", function () {
      if (this.get("model.isNew")) {
        this.get("model").set("body", this.get("model.title"));
      }
    }),
    uploadButtonName: Ember.computed("logoUrl", function () {
      if (Ember.isPresent(this.get("logoUrl"))) {
        return "Change logo";
      } else {
        return "Add logo";
      }
    }),
    logoUrl: Ember.computed("base64Data", "model.isNew", "model.logoUrl", function () {
      if (this.get("model.isNew")) {
        return this.get("base64Data");
      } else {
        return this.get("model.logoUrl");
      }
    }),
    maxDiscountExceeded: Ember.computed("model.percentOff", "model.product.maxDiscount", function () {
      console.log(this.get("model.product.maxDiscount"), this.get("model.percentOff"));

      return this.get("model.percentOff") > this.get("model.product.maxDiscountPercentage") * 100;
    }),
    actions: {
      setDefaultDiscount: function (ad) {
        console.log("setDefaultDiscount", ad);
      },
      addLogo: function (blob) {
        if (this.get("model.isNew")) {
          this.get("model").set("logo", blob.signedId);
          let reader = new FileReader();
          let file = blob.file;
          reader.onload = function (e) {
            this.set("base64Data", e.target.result);
          }.bind(this);

          reader.readAsDataURL(file);
        } else {
          this.get("model").set("logo", blob.signedId);
          this.get("model").save().then(function () {
            this.set("base64Data", null);
          }.bind(this));
        }
      },
      upload: function (event) {
        var downloadingImage = new Image();
        let controller = this;
        const files = event.target.files;
        if (files) {
          let url = Ember.getOwner(this).lookup("adapter:user").buildURL("users", this.get("model.id"), null, null, {});
          const uploader = _uploaders.Uploader.create({ url: url, method: "PUT" });
          controller.set("isUploading", true);
          let ajaxSettings = { headers: this.get("ajax.headers") };
          uploader.set("ajaxSettings", ajaxSettings);
          uploader.upload(files[0]).then(resp => {
            controller.get("store").pushPayload("user", resp);
            downloadingImage.src = this.get("model.avatarThumbnailUrl");
            downloadingImage.onload = function () {
              controller.set("isUploading", false);
            };
          }, error => {
            controller.set("isUploading", false);
            controller.get("flashMessages").danger(error.responseJSON.error, { destroyOnClick: true });
          });
        }
      },
      submit: function () {
        this.get("model.product.content").save();
        this.get("model").save().then(function () {
          this.set("base64Data", null);
          this.get("router").transitionTo("account.settings.deals.index");
        }.bind(this)).catch(function (err) {
          let errorMessage = err.errors[0].detail[0];
          this.get("flashMessages").warning(errorMessage, {
            destroyOnClick: true
          });
        }.bind(this));
      }
    }
  });
});