define("knowme-client/components/quote-form", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let QuoteFormComponent;

  QuoteFormComponent = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    features: Ember.inject.service(),
    today: (0, _moment.default)(),
    flashMessages: Ember.inject.service(),
    printThis: Ember.inject.service(),
    agreement: null,
    isReplying: false,
    isSending: false,
    hasPermissionToText: false,
    didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (Ember.isBlank(this.get("invoice.salesTransactionTarget.id"))) {
        this.set("invoice.contactEmail", this.get("selectedContact.firstContactEmail"));
        if (this.get("selectedContact.contactInfosContactEmails.length") > 0) {
          this.set("invoice.salesTransactionTarget", this.get("selectedContact.firstContactEmail"));
        } else {
          this.set("invoice.salesTransactionTarget", this.get("selectedContact.firstContactPhoneNumber"));
        }
      }
      if (this.get("isMobilePhoneNumber")) {
        this.get("store").findRecord("contact-phone-number", this.get("invoice.salesTransactionTarget.id"), {
          reload: true,
          adapterOptions: { opportunity_id: this.get("opportunity.id") }
        }).then(function (cpn) {
          this.set("hasPermissionToText", cpn.get("allowedToSendTexts"));
        }.bind(this));
      }
    },
    estimateTitle: Ember.computed("opportunity.isClosed", "opportunity.chargeType", function () {
      if (this.get("opportunity.isClosed")) {
        return Ember.isPresent(this.get("opportunity.chargeType")) ? this.get("opportunity.chargeType").replace(/_/g, " ") : "Invoice";
      }
      return "Estimate";
    }),
    chargeType: Ember.computed("opportunity.isClosed", "opportunity.chargeType", function () {
      if (this.get("opportunity.isClosed")) {
        return Ember.isPresent(this.get("opportunity.chargeType")) ? this.get("opportunity.chargeType").replace(/_/g, " ") : "invoice";
      }
      return "estimate";
    }),
    isNotReplayed: Ember.computed("invoice.status", "invoice.hasDirtyAttributes", function () {
      return Ember.isPresent(this.get("invoice")) && (this.get("invoice.status") === "sent" || this.get("invoice.hasDirtyAttributes") && Ember.isPresent(this.get("invoice").changedAttributes().status));
    }),
    contactAddress: Ember.computed("selectedContact", function () {
      return this.get("selectedContact.contactInfosContactAddresses.firstObject");
    }),
    contactEmails: Ember.computed("selectedContact", function () {
      return this.get("selectedContact.contactInfosContactEmails");
    }),
    contactPhoneNumbers: Ember.computed("selectedContact", function () {
      return this.get("selectedContact.contactInfosContactPhoneNumbers");
    }),
    contactEmailsAndPhoneNumbers: Ember.computed("contactEmails", "contactPhoneNumbers", "features.isKnowmeIQFunnel", function () {
      if (this.get("features.isKnowmeIQFunnel")) {
        return this.get("contactEmails");
      }
      return this.get("contactEmails").concat(this.get("contactPhoneNumbers"));
    }),
    disableReplyButton: Ember.computed("invoice.hasDirtyAttributes", "selectedContact.{firstName,lastName}", function () {
      return !this.get("invoice.hasDirtyAttributes") || Ember.isBlank(this.get("selectedContact.firstName")) || Ember.isBlank(this.get("selectedContact.lastName"));
    }),
    isEstimateSent: Ember.computed("chargeType", "invoice.isDraft", function () {
      return this.get("chargeType") === "estimate" && !this.get("invoice.isDraft");
    }),
    isInvoiceSent: Ember.computed("chargeType", "invoice.isDraft", function () {
      return this.get("chargeType") !== "estimate" && !this.get("invoice.isDraft");
    }),
    disableSentButton: Ember.computed("isSending", "isEstimateSent", "invoice.salesTransactionTarget.id", function () {
      return this.get("isSending") || this.get("isEstimateSent") || Ember.isBlank(this.get("invoice.salesTransactionTarget.id"));
    }),
    onTargetChanged: Ember.observer("invoice.salesTransactionTarget", function () {
      if (this.get("isMobilePhoneNumber")) {
        this.get("store").findRecord("contact-phone-number", this.get("invoice.salesTransactionTarget.id"), {
          reload: true,
          adapterOptions: { opportunity_id: this.get("opportunity.id") }
        }).then(function (cpn) {
          this.set("hasPermissionToText", cpn.get("allowedToSendTexts"));
        }.bind(this));
      }
    }),
    isPhoneNumber: Ember.computed("invoice.salesTransactionTarget", "invoice.salesTransactionTarget.contactInfo.category", function () {
      return this.get("invoice.salesTransactionTargetType") === "ContactPhoneNumber";
    }),
    isMobilePhoneNumber: Ember.computed("isPhoneNumber", "invoice.salesTransactionTarget.contactInfo.category", function () {
      return this.get("isPhoneNumber") && this.get("invoice.salesTransactionTarget.contactInfo.category") === "mobile";
    }),
    actions: {
      doPrint: function () {
        const selector = ".sales-transaction";
        const options = {
          printDelay: 500,
          importCSS: true,
          importStyle: true
        };

        this.get("printThis").print(selector, options);
      },
      closeModal: function () {
        this.set("showQuoteForm", false);
      },
      sendQuote: function () {
        let invoice = this.get("invoice");
        if (invoice.then) {
          // when invoice object is PromiseObject
          invoice = this.get("store").peekRecord("sales-transaction", invoice.get("id"));
        }

        invoice.set("status", "sent");
        invoice.set("contact", this.get("selectedContact"));
        this.set("isSending", true);
        invoice.save().then(function () {
          this.set("isSending", false);
          this.send("closeModal");
          this.get("flashMessages").success(`${this.get("estimateTitle")} is sent to ${this.get("invoice.salesTransactionTarget.asString")}`);
        }.bind(this)).catch(function (reason) {
          this.set("isSending", false);
          console.log(reason);
        }.bind(this));
      },
      submitReply: function () {
        this.get("selectedContact").save().then(function () {
          this.get("invoice").save().then(function () {}).catch(function (reason) {
            console.log(reason);
          });
        }.bind(this));
      }
    }
  });

  exports.default = QuoteFormComponent;
});