define("knowme-client/routes/account/settings/business-profile/index", ["exports", "knowme-client/mixins/routes/account-setup"], function (exports, _accountSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    session: Ember.inject.service(),
    account: Ember.computed.alias("session.currentUser.account"),
    //   model: function () {
    //     var model = this.store.createRecord("business-profile");
    //     return model.save();
    //   },
    afterModel: function () {
      let promises = {
        stripeAccount: this.get("store").queryRecord("stripe-account", {}),
        categoties: this.get("store").query("business-category", {}),
        reviewLinks: this.get("store").query("review-link", { status: "active" })
      };

      return Ember.RSVP.hash(promises).then(function (results) {
        this.setProperties(results);
      }.bind(this));
    },
    setupController: function (controller, model) {
      this._super.apply(this, arguments);

      let backendCategories = this.get("categoties").map(function (x) {
        return { id: x.get("id"), name: x.get("name") };
      });

      let categories = [{ id: "restaurant", name: "Restaurant" }, { id: "bakery", name: "Bakery" }, { id: "retail_store", name: "Retail Store" }, { id: "barber_shop", name: "Barber Shop" }, { id: "hair_salon", name: "Hair Salon" }, { id: "plumber", name: "Plumber" }, { id: "electrician", name: "Electrician" }, { id: "cleaning_service", name: "Cleaning Service" }, { id: "consultant", name: "Consultant" }, { id: "hotel", name: "Hotel" }, { id: "gym", name: "Gym" }, { id: "medical_clinic", name: "Medical Clinic" }, { id: "dentist", name: "Dentist" }, { id: "law_firm", name: "Law Firm" }, { id: "real_estate_agency", name: "Real Estate Agency" }, { id: "auto_repair", name: "Auto Repair Shop" }, { id: "moving_company", name: "Moving Company" }, { id: "laundromat", name: "Laundromat" }, { id: "pet_store", name: "Pet Store" }, { id: "car_dealership", name: "Car Dealership" }, { id: "coffee_shop", name: "Coffee Shop" }, { id: "spa", name: "Spa" }, { id: "accounting_firm", name: "Accounting Firm" }, { id: "marketing_agency", name: "Marketing Agency" }, { id: "construction_company", name: "Construction Company" }, { id: "insurance_agency", name: "Insurance Agency" }, { id: "pet_grooming", name: "Pet Grooming" }, { id: "farmers_market", name: "Farmers Market" }, { id: "photography_studio", name: "Photography Studio" }, { id: "non_profit_organization", name: "Non-profit Organization" }];

      backendCategories.forEach(function (x) {
        let exists = categories.find(function (category) {
          return category.id === x.id;
        });
        if (!exists) {
          categories.push({ id: x.id, name: x.name });
        }
      });
      categories.sort((a, b) => a.name.localeCompare(b.name));

      controller.setStatesList();
      controller.initWorkTimes();

      controller.stripeLoad();
      controller.init();
      controller.set("categories", categories);
      controller.set("tags", categories);
      controller.set("savedWorkTimes", Ember.copy(model.get("workTimes"), true));
      controller.set("stripeAccount", this.get("stripeAccount"));

      if (this.get("reviewLinks.length") === 0) {
        let reviewLink = this.store.createRecord("review-link");
        controller.set("reviewLink", reviewLink);
      }

      controller.set("reviewLinks", this.get("reviewLinks"));

      let openPaymentIntegration = localStorage.getItem("openPaymentIntegration");
      localStorage.removeItem("openPaymentIntegration");
      if (Ember.isPresent(this.get("stripeAccount")) && !this.get("stripeAccount.payoutsEnabled") || openPaymentIntegration) {
        controller.set("isPaymentLinkSettingsOpened", true);
        this.get("flashMessages").warning("Payment Integration is not completed", { destroyOnClick: true, timeout: 12000 });
      }
    },
    resetController: function (controller, isExiting /*, transition*/) {
      this._super(...arguments);
      if (isExiting) {
        controller.set("editMode", false);
      }
    }
  });
});