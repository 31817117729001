define("knowme-client/routes/checkout/index", ["exports", "ember-stripe-service/utils/load-script", "knowme-client/config/environment"], function (exports, _loadScript, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { publishableKey } = _environment.default.stripe;

  exports.default = Ember.Route.extend({
    apiKey: Ember.inject.service(),
    store: Ember.inject.service(),
    beforeModel: function (transition) {
      if (Ember.isBlank(transition.queryParams.session_id) || Ember.isBlank(transition.queryParams.invoice) || Ember.isBlank(transition.queryParams.code)) {
        transition.abort();
        return this.transitionTo("solutions");
      }

      this.set("apiKey.code", transition.queryParams.code);
      this.get("store").findRecord("sales-transaction", transition.queryParams.invoice).then(function (invoice) {
        if (invoice.get("isPaid") || invoice.get("paidAt")) {
          transition.abort();
          return this.transitionTo("checkout.success", invoice.get("id"), {
            queryParams: {
              invoice_id: invoice.get("id"),
              code: transition.queryParams.code
            }
          });
        }

        (0, _loadScript.default)("https://js.stripe.com/v3/").then(function () {
          var stripe = Stripe(publishableKey);
          stripe.redirectToCheckout({
            sessionId: transition.queryParams.session_id
          }).then(function (data) {
            console.log(data);
          });
        });
      }.bind(this));
    }
  });
});