define("knowme-client/routes/account/settings/business-profile", ["exports", "knowme-client/mixins/routes/account-setup"], function (exports, _accountSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    session: Ember.inject.service(),
    account: Ember.computed.alias("session.currentUser.account"),
    model: function () {
      var model = this.store.createRecord("business-profile");
      return model.save();
    },
    afterModel: function (model) {
      if (Ember.isBlank(model.get("name"))) {
        model.set("name", this.get("account.name"));
      }

      if (Ember.isBlank(model.get("email"))) {
        model.set("email", this.get("account.contactEmail"));
      }

      if (Ember.isBlank(model.get("phoneNumber"))) {
        model.set("phoneNumber", this.get("account.phoneNumber"));
      }

      if (Ember.isBlank(model.get("addressCountry"))) {
        model.set("addressCountry", this.get("account.addressCountry"));
      }

      if (Ember.isBlank(model.get("addressState"))) {
        model.set("addressState", this.get("account.addressState"));
      }

      if (Ember.isBlank(model.get("addressCity"))) {
        model.set("addressCity", this.get("account.addressCity"));
      }

      if (Ember.isBlank(model.get("addressStreet"))) {
        model.set("addressStreet", this.get("account.addressMainStreet"));
      }

      if (Ember.isBlank(model.get("addressZip"))) {
        model.set("addressZip", this.get("account.addressZip"));
      }

      if (Ember.isBlank(model.get("category"))) {
        model.set("category", this.get("account.industry"));
      }
    },
    setupController: function (controller, model, transition) {
      this._super.apply(this, arguments);

      if (transition.targetName === "account.settings.business-profile.products" || transition.targetName === "account.settings.business-profile.index") {
        controller.set("hideSkeleton", true);
      } else {
        controller.set("hideSkeleton", false);
      }
    }
    // resetController: function (controller, isExiting, transition) {
    //   this._super.apply(this, arguments);
    //   if (
    //     transition.targetName === "account.settings.business-profile.products"
    //   ) {
    //     controller.set("hideSkeleton", true);
    //   } else {
    //     controller.set("hideSkeleton", false);
    //   }
    // },
  });
});