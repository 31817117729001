define("knowme-client/components/deals/vendor-info", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentDay: Ember.computed("moment", function () {
      return (0, _moment.default)().format("ddd").toLocaleLowerCase();
    })
  });
});