define("knowme-client/routes/account/settings/deals/checkout", ["exports", "ember-stripe-service/utils/load-script", "knowme-client/config/environment", "knowme-client/mixins/routes/account-setup"], function (exports, _loadScript, _environment, _accountSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { publishableKey } = _environment.default.stripe;

  exports.default = Ember.Route.extend(_accountSetup.default, {
    beforeModel: function (transition) {
      let sessionId = transition.params["account.settings.deals.checkout"].session_id;
      (0, _loadScript.default)("https://js.stripe.com/v3/").then(function () {
        var stripe = Stripe(publishableKey);
        stripe.redirectToCheckout({ sessionId: sessionId }).then(function (data) {
          console.log(data);
        });
      });
    }
  });
});