define("knowme-client/utils/intl/missing-message", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = missingMessage;
  function missingMessage(key, locales) {
    throw new Error(`[ember-intl] Missing translation for key: "${key}" for locales: "${locales}"`);
  }
});