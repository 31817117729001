define("knowme-client/controllers/account/settings/business-profile/our-interests", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    businessProfileController: Ember.inject.controller("account.settings.business-profile"),
    model: Ember.computed.alias("businessProfileController.model"),
    interestCategories: Ember.computed("model.interestCategories", function () {
      return [{ id: "restaurant", name: "Restaurant" }, { id: "custom", name: "Custom" }];
    }),
    categories: [{ id: "restaurant", name: "Restaurant" }, { id: "bakery", name: "Bakery" }, { id: "retail_store", name: "Retail Store" }, { id: "barber_shop", name: "Barber Shop" }, { id: "hair_salon", name: "Hair Salon" }, { id: "plumber", name: "Plumber" }, { id: "electrician", name: "Electrician" }, { id: "cleaning_service", name: "Cleaning Service" }, { id: "consultant", name: "Consultant" }, { id: "hotel", name: "Hotel" }, { id: "gym", name: "Gym" }, { id: "medical_clinic", name: "Medical Clinic" }, { id: "dentist", name: "Dentist" }, { id: "law_firm", name: "Law Firm" }, { id: "real_estate_agency", name: "Real Estate Agency" }, { id: "auto_repair", name: "Auto Repair Shop" }, { id: "moving_company", name: "Moving Company" }, { id: "laundromat", name: "Laundromat" }, { id: "pet_store", name: "Pet Store" }, { id: "car_dealership", name: "Car Dealership" }, { id: "coffee_shop", name: "Coffee Shop" }, { id: "spa", name: "Spa" }, { id: "accounting_firm", name: "Accounting Firm" }, { id: "marketing_agency", name: "Marketing Agency" }, { id: "construction_company", name: "Construction Company" }, { id: "insurance_agency", name: "Insurance Agency" }, { id: "pet_grooming", name: "Pet Grooming" }, { id: "farmers_market", name: "Farmers Market" }, { id: "photography_studio", name: "Photography Studio" }, { id: "non_profit_organization", name: "Non-profit Organization" }],
    disableSubmit: Ember.computed("model.validations.attrs.category.isInvalid", function () {
      return this.get("model.validations.attrs.category.isInvalid");
    }),
    actions: {
      back: function () {
        this.get("router").transitionTo("account.settings.business-profile.products");
      },
      createOnEnter: function (object, evt) {
        if (evt.keyCode === 13) {
          let option = {
            id: object.lastSearchedText.toLocaleLowerCase().replace(/\s/g, "_"),
            name: object.lastSearchedText
          };
          object.selected.push(option);
          this.get("categories").push(option);
          this.get("model.interestCategories").push(option);
        }
      },
      submit: function () {
        this.get("model").save().then(function () {
          this.get("router").transitionTo("account.settings.business-profile.payment-method");
        }.bind(this));
      }
    }
  });
});