define("knowme-client/models/business-category", ["exports", "ember-data/model", "ember-data/attr"], function (exports, _model, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // id: attr(),
    name: (0, _attr.default)()
  });
});