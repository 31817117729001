define("knowme-client/routes/pricing", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  let KnowmeIQProductRoute;

  KnowmeIQProductRoute = Ember.Route.extend({
    session: Ember.inject.service(),
    actions: {},
    model: function () {
      return this.get("store").query("plan", {
        key: ["knowme_iq.funnel", "knowme_iq.toolbox"]
      });
    },
    setupController: function (controller, model) {
      this._super(...arguments);
      let funnelPlan = model.find(x => x.get("key") === "knowme_iq.funnel");
      let toolboxPlan = model.find(x => x.get("key") === "knowme_iq.toolbox");

      controller.set("funnelPlan", funnelPlan);
      controller.set("toolboxPlan", toolboxPlan);
    }
  });

  exports.default = KnowmeIQProductRoute;
});