define("knowme-client/routes/marketplace/leads", ["exports", "lodash", "moment", "knowme-client/mixins/routes/account-setup"], function (exports, _lodash, _moment, _accountSetup) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_accountSetup.default, {
    session: Ember.inject.service("session"),
    pagination: {},
    model: function (params) {
      let route = this;
      let leadsPromise = route.get("store").query("lead", this.processParams(params));
      leadsPromise.then(result => {
        result.forEach(function (item) {
          item.set("opportunitiesDisplayed", true);
        });
        this.set("pagination.total", result.meta.pagination.total);
        this.set("pagination.limit", result.meta.pagination.limit);
        this.set("pagination.page", result.meta.pagination.page);
      });

      return leadsPromise;
    },
    processParams: function (_params) {
      let params = Ember.copy(_params);

      if (Ember.isPresent(params.filter) && typeof params.filter === "string") {
        params.filter = Ember.A(params.filter.split(","));
      } else {
        params.filter = Ember.A([]);
      }
      params.filter.pushObject("marketplace");

      // default ordering by Filter
      if (params.filter.includes("past_due_action")) {
        params.sort_by = "due_date";
        params.order = params.order || "desc";
      } else if (params.filter.includes("action_required")) {
        params.sort_by = "due_date";
        params.order = params.order || "desc";
      } else if (params.filter.includes("priority")) {
        params.sort_by = "priority";
        params.order = params.order || "asc";
      } else if (params.sort_by === null) {
        params.sort_by = "updated_at";
        params.order = params.order || "desc";
      }

      if (params.converted === "1" && !params.filter.includes("all_leads_and_customers") && !params.filter.includes("customers_and_leads_pipeline") && !params.filter.includes("pipeline_by_user")) {
        params.filter.pushObject("customers");
      }

      if (params.converted !== "1" && !params.filter.includes("all_leads_and_customers") && !params.filter.includes("customers_and_leads_pipeline") && !params.filter.includes("pipeline_by_user") && Ember.isBlank(["acquired_today", "acquired_ytd", "converted_today", "converted_ytd", "converted"].filter(function (n) {
        return (params.filter || Ember.A([])).includes(n);
      }))) {
        params.filter.pushObject("leads");
      }

      if (params.filter.includes("pipeline_by_user")) {
        params.filter.removeObject("pipeline_by_user");
        params.filter.pushObject("pipeline_opportunity");
        params.filter.pushObject("assigned_to");
        params.filter.pushObject("all");
      }

      if (!params.filter.includes("all") && (params.filter.includes("all_leads_and_customers") || params.filter.includes("customers_and_leads_pipeline")) || Ember.isPresent(["converted_today", "converted_ytd"].filter(function (n) {
        return (params.filter || Ember.A([])).includes(n);
      }))) {
        params.filter.pushObject("all");
        if (params.filter.includes("customers_and_leads_pipeline")) {
          params.filter.pushObject("pipeline_opportunity");
        }
      }

      delete params.converted;
      params.filter.removeObject("customers_and_leads_pipeline");
      params.filter.removeObject("all_leads_and_customers");

      if (Ember.isPresent(["past_due_action", "action_required"].filter(function (n) {
        return (params.filter || []).includes(n);
      })) && (Ember.isPresent(params.from_date) || Ember.isPresent(params.to_date))) {
        params.field = "due_date";
      }

      if (Ember.isPresent(["converted"].filter(function (n) {
        return (params.filter || []).includes(n);
      })) && (Ember.isPresent(params.from_date) || Ember.isPresent(params.to_date))) {
        params.field = "conversion_date";
      }

      if (Ember.isPresent(["appointments"].filter(function (n) {
        return (params.filter || []).includes(n);
      })) && (Ember.isPresent(params.from_date) || Ember.isPresent(params.to_date))) {
        params.field = "appointment_date";
      }

      return _lodash.default.mapValues(_lodash.default.pickBy(params, _lodash.default.identity), function (value, key) {
        if (key === "from_date" || key === "to_date" || key === "on_date" || key === "converted_at") {
          return (0, _moment.default)(value, "MM-DD-YYYY").format("DD-MM-YYYY");
        }
        return value;
      });
    },
    afterModel: function (model, transition) {
      return Ember.RSVP.hash({
        columns: this.get("store").query("column-setting", {
          enable: true,
          target_name: transition.queryParams.converted === "1" ? "customers" : "leads"
        })
      }).then(function (data) {
        this.set("columns", data.columns);
        this.set("params", this.processParams(transition.queryParams));
      }.bind(this));
    },
    setupController: function (controller) {
      this._super.apply(this, arguments);
      // Model is already set, so we have to explicitly update controller
      controller.set("total", this.get("pagination.total"));
      controller.set("limit", this.get("pagination.limit"));
      controller.set("count", Math.ceil(this.get("pagination.total") / this.get("pagination.limit")));
      controller.set("current", this.get("pagination.page"));
      controller.set("columns", this.get("columns"));

      controller.set("params", this.get("params"));
      this.set("params", {});

      Ember.RSVP.hash({
        // users: users,
        stages: this.get("store").query("sales_stage", { paginate: false }),
        // campaigns: this.get("store").query("campaign", {
        //   paginate: false,
        //   sort_by: "name",
        //   order: "asc",
        // }),
        deals: this.get("store").query("deal", {})
      }).then(function (response) {
        controller.setProperties(response);
      });
    },
    actions: {
      setOrder: function (sortBy, order) {
        this.transitionTo({ queryParams: { sort_by: sortBy, order: order } });
      },
      changePage: function (current) {
        this.transitionTo({ queryParams: { page: current } });
      },
      setQuery: function (query) {
        this.transitionTo({ queryParams: { query: query } });
      },
      queryParamsDidChange: function () {
        this.refresh();
      },
      willTransition: function (transition) {
        if (this.get("controller.converted") !== transition.queryParams.converted) {
          this.render("loading", { into: "application" });
        }
      }
    }
  });
});