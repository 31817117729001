define("knowme-client/helpers/format-country", ["exports", "knowme-client/mixins/us-states"], function (exports, _usStates) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCountry = formatCountry;
  function formatCountry(params /*, hash*/) {
    let code = params[0] || 0;
    let country = _usStates.default.mixins[0].properties.countries.find(x => x.id === code).name;
    return country;
  }

  exports.default = Ember.Helper.helper(formatCountry);
});