define("knowme-client/models/opportunity", ["exports", "ember-data/model", "ember-data/relationships", "ember-data/attr", "moment", "knowme-client/mixins/models/validations/opportunity", "knowme-client/mixins/models/ember-cp-validations-custom-properties", "knowme-client/mixins/dirty", "knowme-client/mixins/validate-belongs-to"], function (exports, _model, _relationships, _attr, _moment, _opportunity, _emberCpValidationsCustomProperties, _dirty, _validateBelongsTo) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Opportunity;

  Opportunity = _model.default.extend(_opportunity.default, _emberCpValidationsCustomProperties.default, _dirty.default, _validateBelongsTo.default, {
    // Relationships
    lead: (0, _relationships.belongsTo)("lead", { async: true }),
    orders: (0, _relationships.hasMany)("order", { async: true }),
    attachments: (0, _relationships.hasMany)("file", { async: true }),
    customAttributes: (0, _relationships.hasMany)("custom-attribute", { async: true }),
    salesTransactions: (0, _relationships.hasMany)("sales-transaction", { async: true }),
    lastSalesTransaction: (0, _relationships.belongsTo)("sales-transaction", { async: true }),
    assignedTo: (0, _relationships.belongsTo)("user", { async: true }),
    ad: (0, _relationships.belongsTo)("ad", { async: true }),
    salesAction: (0, _relationships.belongsTo)("sales-action", { async: true }),
    salesStage: (0, _relationships.belongsTo)("sales-stage", { async: true }),
    calls: (0, _relationships.hasMany)("call", { async: true }),
    shortMessages: (0, _relationships.hasMany)("short-message", { async: true }),
    activities: (0, _relationships.hasMany)("lead-activity", { async: true }),
    customerExperiences: (0, _relationships.hasMany)("customer-experience", { async: true }),
    lastActivity: (0, _relationships.belongsTo)("lead-activity", { async: true }),
    firstActivity: (0, _relationships.belongsTo)("lead-activity", { async: true }),
    firstShortMessage: (0, _relationships.belongsTo)("short-message", { async: true }),
    lastShortMessage: (0, _relationships.belongsTo)("short-message", { async: true }),
    // Attributes
    files: (0, _attr.default)(),
    name: (0, _attr.default)("string", { defaultValue: "" }),
    leadId: (0, _attr.default)(),
    opportunityId: (0, _attr.default)("number"),
    total: (0, _attr.default)("number", { defaultValue: 0 }),
    priority: (0, _attr.default)("string"),
    chargeType: (0, _attr.default)("string"),
    activity: (0, _attr.default)("string"),
    surveyStatus: (0, _attr.default)("string"),
    note: (0, _attr.default)("string"),
    dueDate: (0, _attr.default)("date"),
    forecastDate: (0, _attr.default)("date"),
    appointmentDate: (0, _attr.default)("date"),
    createdAt: (0, _attr.default)("date"),
    updatedAt: (0, _attr.default)("date"),
    conversionDate: (0, _attr.default)("date"),
    lastContactDate: (0, _attr.default)("date"),
    adId: Ember.computed.alias("ad.id"),
    adIdHasChanged: Ember.computed.alias("adHasChanged"),
    isSingle: (0, _attr.default)("boolean"),
    isRelatedToCustomer: (0, _attr.default)("boolean"),
    isMarketplace: (0, _attr.default)("boolean"),
    shipping: (0, _attr.default)("number", { defaultValue: 0 }),
    // Computed
    hasCTP: Ember.computed("ad.callTouchPoints.@each.id", function () {
      return this.get("ad.callTouchPoints.length") > 0;
    }),
    lastActivityDate: Ember.computed("lastActivity.createdAt", function () {
      return this.get("lastActivity.createdAt");
    }),
    calculatedTotal: Ember.computed("subTotal", "shipping", "totalTax", function () {
      return parseFloat(this.get("subTotal")) + parseFloat(this.get("totalTax")) + parseFloat(this.get("shipping"));
    }),
    subTotal: Ember.computed("orders.@each.calculatedTotal", function () {
      return this.get("orders").reduce(function (pipeline, order) {
        pipeline += order.get("calculatedTotal");
        return pipeline;
      }, 0);
    }),
    taxableSubTotal: Ember.computed("orders.@each.calculatedTotal", function () {
      return this.get("orders").filterBy("taxable", true).reduce(function (pipeline, order) {
        if (order.get("taxRate") > 0) {
          pipeline += order.get("calculatedTotal");
        }
        return pipeline;
      }, 0);
    }),
    totalTax: Ember.computed("orders.@each.calculatedTax", function () {
      return this.get("orders").reduce(function (tax, order) {
        tax += order.get("calculatedTax");
        return tax;
      }, 0);
    }),
    pipeline: Ember.computed("orders.@each.calculatedTotal", function () {
      return this.get("orders").reduce(function (pipeline, order) {
        pipeline += order.get("calculatedTotal");
        return pipeline;
      }, 0);
    }),
    profit: Ember.computed("orders.@each.profit", function () {
      return this.get("orders").reduce(function (profit, order) {
        profit += order.get("profit");
        return profit;
      }, 0);
    }),
    lastUpdatedOrder: Ember.computed("orders.@each.updatedAt", function () {
      let max;
      return this.get("orders").reduce(function (previousOrder, currentOrder) {
        max = previousOrder;
        if (Ember.isNone(previousOrder) || (0, _moment.default)(previousOrder.get("updatedAt")).isBefore(currentOrder.get("updatedAt"))) {
          max = currentOrder;
        }
        return max;
      });
    }),
    ordersLength: Ember.computed.alias("orders.length"),
    salesStageKey: Ember.computed.alias("salesStage.key"),
    isUnqualified: Ember.computed.equal("salesStage.key", "unqualified"),
    isClosed: Ember.computed.equal("salesStage.key", "closed"),
    isLost: Ember.computed.equal("salesStage.key", "lost"),
    isAppointment: Ember.computed.equal("salesStage.key", "appointment"),
    mappedStage: Ember.computed("salesStage.key", function () {
      if (this.get("isClosed")) {
        return "Closed/Won";
      } else if (this.get("isLost")) {
        return "Closed/Lost";
      } else if (this.get("isUnqualified")) {
        return "(none)";
      } else {
        return this.get("salesStage.name");
      }
    }),
    nonDeletedOrders: Ember.computed.filterBy("orders", "isDeleted", false),
    nonDeletedAndSavedOrders: Ember.computed.filterBy("nonDeletedOrders", "isNew", false),
    ordersSorting: ["createdAt:desc"],
    ordersSorted: Ember.computed.sort("orders", "ordersSorting"),
    attributesSorting: ["position:asc"],
    customAttributesSorted: Ember.computed.sort("customAttributes", "attributesSorting"),
    callActivities: Ember.computed.filter("activities", function (activity) {
      return ["inbound_call", "outbound_call"].includes(activity.get("body.opportunity.activity")) || activity.get("body.communication.resourceType") === "Call";
    }),
    emailActivities: Ember.computed.filter("activities", function (activity) {
      return activity.get("body.communication.resourceType") === "Email";
    }),
    smsActivities: Ember.computed.filter("activities", function (activity) {
      if (Ember.isPresent(activity.get("body.communication"))) {
        return activity.get("body.communication.resourceType") === "ShortMessage";
      } else {
        return false;
      }
    }),
    quotesActivities: Ember.computed.filter("activities", function (activity) {
      return ["quote_sent", "quote_accepted", "quote_rejected"].includes(activity.get("body.opportunity.activity"));
    }),
    anyActivities: Ember.computed.filter("activities", function (activity) {
      return Ember.isBlank(activity.get("body.communication"));
    }),
    _activitiesSorting: ["createdAt:desc"],
    sortedActivities: Ember.computed.sort("activities", "_activitiesSorting"),
    // observers
    onAttributesChange: Ember.observer("name", "salesStage.id", "salesAction.id", "priority", "dueDate", "forecastDate", "ad.id", "assignedTo.id", "activity", function () {
      if (Ember.isBlank(this.get("activity"))) {
        if (this.get("nameHasChanged") || this.get("salesStageHasChanged") || this.get("salesActionHasChanged") || this.get("priorityHasChanged") || this.get("dueDateHasChanged") || this.get("forecastDateHasChanged") || this.get("adHasChanged") || this.get("assignedToHasChanged")) {
          this.set("activity", "update");
        }
      }
    }),
    selectedActivity: null
  });

  exports.default = Opportunity;
});