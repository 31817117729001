define("knowme-client/controllers/account/settings/deals", ["exports", "lodash", "knowme-client/mixins/controllers/pagination", "knowme-client/mixins/controllers/status", "knowme-client/mixins/controllers/queryable", "knowme-client/mixins/date-filter"], function (exports, _lodash, _pagination, _status, _queryable, _dateFilter) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_dateFilter.default, _pagination.default, _status.default, _queryable.default, {
    flashMessages: Ember.inject.service(),
    features: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    queryParams: ["sort_by", "order", "page", "status", "deal_status", "stage", "query", "filter"],
    defaultsQueryParams: Ember.computed(function () {
      return { status: "active", sort_by: "revenue", order: "desc" };
    }),
    query: null,
    sort_by: null,
    order: null,
    leadsController: Ember.inject.controller("leads.index"),
    router: Ember.inject.service(),
    statusTitle: Ember.computed("status", function () {
      let statusItem = _lodash.default.find(this.get("statusDealOptions"), {
        value: this.get("status")
      });
      return statusItem.text;
    }),
    statusDealTitle: Ember.computed("deal_status", function () {
      let statusItem = _lodash.default.find(this.get("statusDealOptions"), {
        value: this.get("deal_status")
      });
      return statusItem.text;
    }),
    statusDealOptions: Ember.computed("filter", function () {
      if (this.get("filter") === "purchases" && this.get("status") === "unpaid") {
        return [{ value: "active", text: "Active Deals" }, { value: "expired", text: "Expired Deals" }];
      } else {
        return [{ value: "active", text: "Active" }, { value: "expired", text: "Expired" }];
      }
    }),
    stageOptions: Ember.computed("stage", function () {
      if (this.get("stage") === "with_revenue") {
        return [{ value: "with_revenue", text: "With Revenue" }];
      }
      return [{ value: "with_revenue", text: "With Revenue" }, { value: "with_pipeline", text: "With Pipeline" }];
    }),
    firstContactEmail: Ember.computed.alias("emailInfo.contactEmails.firstObject"),
    firstContactNumber: Ember.computed.alias("phoneNumberInfo.contactPhoneNumbers.firstObject"),
    emailInfo: Ember.computed("contact", function () {
      if (Ember.isPresent(this.get("contact.contactInfos").filterBy("category", "work"))) {
        return this.get("contact.contactInfos").filterBy("category", "work").get("firstObject");
      } else {
        return this.get("store").createRecord("contact-info", {
          category: "work",
          contact: this.get("contact")
        });
      }
    }),
    phoneNumberInfo: Ember.computed("contact", "contact.contactInfos.@each.contactPhoneNumbers", function () {
      if (Ember.isPresent(this.get("contact.contactInfos").filterBy("category", "mobile"))) {
        return this.get("contact.contactInfos").filterBy("category", "mobile").get("firstObject");
      } else {
        return this.get("store").createRecord("contact-info", {
          category: "mobile",
          contact: this.get("contact")
        });
      }
    }),
    isContactTreeDirty: Ember.computed("contact.hasDirtyAttributes", "contact.contactInfosContactEmails.@each.contactInfoHasChanged", "contact.contactInfosContactPhoneNumbers.@each.contactInfoHasChanged", "contact.contactInfos.@each.hasDirtyAttributes", "contact.contactInfosContactEmails.@each.hasDirtyAttributes", "contact.contactInfosContactPhoneNumbers.@each.hasDirtyAttributes", function () {
      return this.get("contact.hasDirtyAttributes") || this.get("contact.contactInfosContactEmails").isAny("contactInfoHasChanged", true) || this.get("contact.contactInfosContactPhoneNumbers").isAny("contactInfoHasChanged", true) || this.get("contact.contactInfos").isAny("hasDirtyAttributes", true) || this.get("contact.contactInfosContactEmails").isAny("hasDirtyAttributes", true) || this.get("contact.contactInfosContactPhoneNumbers").isAny("hasDirtyAttributes", true);
    }),
    isDisabled: Ember.computed("opportunity.validations.isValid", "contact.validations.isValid", "isContactTreeDirty", "opportunity.orders.@each.hasDirtyAttributes", function () {
      return this.get("opportunity.validations.isInvalid") || this.get("contact.validations.isInvalid");
    }),
    actions: {
      submit: function () {
        this.set("isPressed", true);
        let leadAttributes = this.get("lead").serialize();
        let accountDeal = this.get("store").createRecord("account-deal", {
          leadAttributes: leadAttributes,
          deal: this.get("selectedDeal")
        });
        accountDeal.save().then(function (accountDeal) {
          this.set("isPressed", false);
          this.set("selectedDeal", null);
          this.set("lead", null);

          let url = this.get("router").urlFor("account.settings.deals.checkout", accountDeal.get("sessionId"));

          window.open(url);
        }.bind(this)).catch(function () {
          this.set("isPressed", false);
        }.bind(this));
      },
      openDeal: function (deal) {
        if (deal.get("hasExpired")) {
          this.get("flashMessages").warning(`Ad ${deal.get("title")} has expired`, {
            destroyOnClick: true,
            timeout: 12000
          });
          return;
        }
        let dealView = this.get("store").createRecord("deal-view", {
          deal: deal
        });
        dealView.save();
        if (deal.get("product").then) {
          deal.get("product").then(function (product) {
            let lead = this.set("lead", this.store.createRecord("lead", {
              source: "deal"
            }));
            "session.currentUser.account.contactEmail";
            let contact = this.get("store").createRecord("contact", {
              lead: lead,
              primary: true,
              firstName: this.get("session.currentUser.account.contactFirstName"),
              lastName: this.get("session.currentUser.account.contactLastName")
            });
            this.set("contact", contact);

            this.get("store").createRecord("contactEmail", {
              email: this.get("session.currentUser.account.contactEmail"),
              contactInfo: this.get("emailInfo")
            });
            this.get("store").createRecord("contactPhoneNumber", {
              tenDigitNumber: "+1" + this.get("session.currentUser.account.phoneNumber"),
              contactInfo: this.get("phoneNumberInfo")
            });

            if (this.get("features.account.isbtob")) {
              this.store.createRecord("organization", {
                name: this.get("session.currentUser.account.name"),
                lead: lead
              });
            }
            let opportunity = this.store.createRecord("opportunity", {
              lead: lead,
              name: "Automatically Generated by Ads submit"
            });

            let order = this.store.createRecord("order", {
              opportunity: opportunity
            });

            order.set("product", product);
            order.set("discount", deal.get("percentOff"));

            opportunity.set("orders", [order]);
            this.set("lead", lead);
            this.set("opportunity", opportunity);
            this.set("selectedDeal", deal);
          }.bind(this));
        }
      },
      copyAd: function (resource) {
        let attrs = {
          title: resource.get("title"),
          body: resource.get("body"),
          product: resource.get("product")
        };
        this.set("dealAttributes", attrs);
        this.get("router").transitionTo("account.settings.deals.new", {
          queryParams: { from: resource.get("id") }
        });
      },
      displaySelector: function (contactResource) {
        contactResource.set("displaySelector", !contactResource.get("displaySelector"));
      },
      changeCategory: function (category, contactResource) {
        contactResource.set("displaySelector", false);
        let contactInfo = contactResource.get("contactInfo");
        contactInfo.set("category", category);
      },
      addContactEmail: function () {
        var email = this.get("email");

        if (Ember.isPresent(email) && email.match(/^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,}$/i)) {
          this.get("store").createRecord("contactEmail", {
            email: email,
            contactInfo: this.get("emailInfo")
          });
          this.set("email", "");
          Ember.run.later(function () {
            Ember.$(".email-input input").focus();
          }, 200);
        }
      },
      removeContactEmailIfEmpty: function (contactEmail) {
        if (Ember.isBlank(contactEmail.get("email"))) {
          contactEmail.deleteRecord();
        }
      },
      addContactPhoneNumber: function () {
        let phone = this.get("phone");
        if (Ember.isPresent(phone) && phone.length === 12) {
          this.get("store").createRecord("contactPhoneNumber", {
            tenDigitNumber: phone,
            contactInfo: this.get("phoneNumberInfo")
          });
          this.set("phone", "");
          Ember.run.later(function () {
            Ember.$(".phone-input input").focus();
          }, 200);
        }
      },
      removeContactPhoneIfEmpty: function (contactPhoneNumber) {
        if (Ember.isBlank(contactPhoneNumber.get("tenDigitNumber"))) {
          contactPhoneNumber.deleteRecord();
        }
      },
      setDefaultDiscount: function (order) {
        if (Ember.isBlank(order.get("discount"))) {
          order.set("discount", "0");
        }
      }
    }
  });
});