define("knowme-client/models/dashboard-widget", ["exports", "ember-data/model", "ember-data/attr"], function (exports, _model, _attr) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    title: (0, _attr.default)(),
    key: (0, _attr.default)(),
    path: Ember.computed("key", "session.currentUser.account.industry", function () {
      if (this.get("key") === "customers") {
        try {
          let industry = this.get("session.currentUser.account.industry");
          this.get("intl").t(`dashboard_widget.${industry}`);
          return industry;
        } catch (err) {
          return "customers";
        }
      }
      if (this.get("key") === "customers") {
        return;
      }
      return this.get("key").replace(/\s+/g, "_");
    }),
    templateName: Ember.computed("key", function () {
      return "dashboard/" + this.get("key").replace(/_/g, "-") + "-widget";
    })
  });
});