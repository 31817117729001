define("knowme-client/controllers/pricing", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var KnowmeIQProductController;

  KnowmeIQProductController = Ember.Controller.extend({
    interval: "monthly",
    promoCodeLength: 8,
    init: function () {
      this._super();
      this.set("planData", {
        funnel: {
          fee: 45,
          totalUsers: 2,
          totalCallTouchPointsDidsLocal: 4,
          multiRings: 1,
          totalAutopylotNumbers: 0,
          totalMinutes: 300,
          totalMessages: 300,
          totalAds: 6
        },
        toolbox: {
          fee: 160,
          totalUsers: 2,
          totalCallTouchPointsDidsLocal: 8,
          multiRings: 1,
          totalAutopylotNumbers: 1,
          totalMinutes: 1000,
          totalMessages: 1000,
          totalAds: 20
        },
        premium: {
          fee: 160,
          totalUsers: 2,
          totalCallTouchPointsDidsLocal: 10,
          multiRings: 1,
          totalAutopylotNumbers: 2,
          totalMinutes: 1000,
          totalMessages: 1000,
          totalAds: 20
        }
      });
    },
    actions: {
      applyPromoCode: function (promoCodeId, planKey) {
        if (Ember.isPresent(promoCodeId)) {
          let controller = this;
          this.get("store").query("promo-code", {
            coupon_id: promoCodeId,
            plan_key: planKey,
            billing_period: this.get("interval")
          }).then(function (promoCodes) {
            if (promoCodes.get("length") > 0) {
              if (planKey === "knowme_iq.toolbox") {
                controller.set("toolboxPromoCode", promoCodes.get("firstObject"));
              }
              if (planKey === "knowme_iq.funnel") {
                controller.set("funnelPromoCode", promoCodes.get("firstObject"));
              }
            } else {
              controller.get("flashMessages").warning(`Promo Code ${promoCodeId} does not exist`, {
                destroyOnClick: true,
                timeout: 12000
              });
            }
          }, function (resp) {
            controller.get("flashMessages").warning(resp.errors[0].detail, {
              destroyOnClick: true,
              timeout: 12000
            });
          });
        }
      },
      toggleInteval: function () {
        if (this.get("interval") === "annually") {
          this.set("interval", "monthly");
        } else {
          this.set("interval", "annually");
        }
      },
      setSignupData: function (data) {
        this.set("signupData", data);
      }
    }
  });

  exports.default = KnowmeIQProductController;
});