define("knowme-client/components/account/resource-form", ["exports", "ember-concurrency", "knowme-client/mixins/us-states", "knowme-client/mixins/timezones", "knowme-client/mixins/controllers/status", "knowme-client/mixins/industries", "knowme-client/config/environment"], function (exports, _emberConcurrency, _usStates, _timezones, _status, _industries, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_usStates.default, _timezones.default, _status.default, _industries.default, {
    store: Ember.inject.service(),
    features: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    accountService: Ember.inject.service("account"),
    session: Ember.inject.service(),
    extraPage: false,
    isSaving: false,
    routing: Ember.inject.service("-routing"),
    showLinkInstruction: false,
    showCancelSubscriptionConfirmation: false,
    billingTypesOptions: [{ value: "manual", text: "Manual" }, { value: "automatic", text: "Automatic" }],
    statePromt: "State",
    codeLabel: "Zip",
    percentageMaskOptions: { suffix: " %" },
    industries: Ember.computed("industryOptions", "model.industry", function () {
      let text = this.get("model.industry").split("_").map(x => x.capitalize()).join(" ");
      this.get("industryOptions").push({
        value: this.get("model.industry"),
        text: text
      });
      return this.get("industryOptions");
    }),
    didReceiveAttrs() {
      this._super(...arguments);

      this.setStatesList();
      if (this.get("session.currentUser.isMasterAdmin")) {
        let _this = this;
        Ember.defineProperty(_this, "parentAccountHasChanged", Ember.computed("model.parentAccountHasChanged", () => {
          return this.get("model.parentAccountHasChanged");
        }));
        Ember.defineProperty(_this, "clientAdminsHaveChanged", Ember.computed("clientAdmins", "admins", "clientAdmins.@each.rolesHasChanged", "admins.@each.rolesHasChanged", () => {
          return Ember.isPresent(this.get("clientAdmins")) && this.get("clientAdmins").isAny("rolesHasChanged", true) || Ember.isPresent(this.get("admins")) && this.get("admins").isAny("rolesHasChanged", true);
        }));
      } else {
        Ember.defineProperty(this, "parentAccountHasChanged", Ember.computed("model", () => {
          return false;
        }));
        Ember.defineProperty(this, "clientAdminsHaveChanged", Ember.computed("", () => {
          return false;
        }));
      }
    },
    onTaxRateLoaded: Ember.observer("model.settingsTaxRate.id", function () {
      if (Ember.isPresent(this.get("model.settingsTaxRate.id")) && Ember.isBlank(this.get("model.settingsTaxRate.value"))) {
        // made field isDirty
        this.set("model.settingsTaxRate.value", "");
      }
    }),
    searchParentAccounts: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(800);

      return this.get("store").query("account", {
        parent_account_id: null,
        id_number: term,
        paginate: false,
        sort_by: "name",
        order: "asc"
      }).then(function (response) {
        return response;
      });
    }),
    onCountrySelect: Ember.observer("model.addressCountry", function () {
      this.setStatesList();
      if (!this.get("currentStates").mapBy("id").includes(this.get("model.addressState"))) {
        this.set("model.addressState", null);
      }
    }),
    setStatesList: function () {
      if (Ember.isPresent(this.get("model.addressCountry"))) {
        if (this.get("model.addressCountry") === "us") {
          this.set("statePromt", "State");
          this.set("codeLabel", "Zip");
          this.set("currentStates", this.get("usStates"));
        } else {
          this.set("statePromt", "Province");
          this.set("codeLabel", "Postal");
          this.set("currentStates", this.get("canadianProvinces"));
        }
      } else {
        this.set("currentStates", []);
      }
    },
    clientAdminRole: Ember.computed("roles", function () {
      return this.get("roles").findBy("key", "client_admin");
    }),
    adminRole: Ember.computed("roles", function () {
      return this.get("roles").findBy("key", "admin");
    }),
    clientAdmins: Ember.computed("model.parentAccount", "model.parentAccount.clientAdministrators", function () {
      return this.get("model.parentAccount.clientAdministrators");
    }),
    admins: Ember.computed("model.parentAccount", "model.parentAccount.administrators", function () {
      return this.get("model.parentAccount.administrators");
    }),
    actions: {
      restoreSubscription: function () {
        let resource = this.get("model");
        resource.set("subscription.kind", "paid");
        this.set("isSubscriptionSaving", true);
        resource.save().then(function (resource) {
          this.set("showCancelSubscriptionConfirmation", false);
          this.set("isSubscriptionSaving", false);
          resource.belongsTo("subscription").reload();
        }.bind(this));
      },
      cancelSubscription: function () {
        let resource = this.get("model");
        resource.set("subscription.kind", "cancelled");
        this.set("isSubscriptionSaving", true);
        resource.save().then(function (resource) {
          this.set("showCancelSubscriptionConfirmation", false);
          this.set("isSubscriptionSaving", false);
          resource.belongsTo("subscription").reload();
        }.bind(this));
      },
      submit: function () {
        let resource = this.get("model");
        this.set("isSaving", true);
        if (this.get("session.currentUser.isMasterAdmin") && this.get("model.parentAccount") && this.get("clientAdminsHaveChanged")) {
          this.get("clientAdmins").forEach(function (clientAdmin) {
            if (clientAdmin.get("rolesHasChanged")) {
              clientAdmin.save();
            }
          });
          this.get("admins").forEach(function (admin) {
            if (admin.get("rolesHasChanged")) {
              admin.save();
            }
          });
        }
        if (resource.get("isNew")) {
          let _this = this;
          let availableSettings = Object.keys(_this.get("planSettings")).concat(Object.keys(_this.get("addOns")));
          _environment.default.APP.SettingsKeys.forEach(key => {
            if (!availableSettings.includes(key.camelize())) {
              return;
            }
            if (Ember.isPresent(_this.get(`addOns.${key.camelize()}`))) {
              _this.get("store").createRecord("account-setting", {
                key: key,
                value: _this.get(`addOns.${key.camelize()}`),
                account: resource
              });
            } else {
              _this.get("store").createRecord("account-setting", {
                key: key,
                value: _this.get(`planSettings.${key.camelize()}`),
                account: resource
              });
            }
          });
        }

        resource.save().then(function (resource) {
          if (Ember.isPresent(this.get("afterSave"))) {
            this.afterSave(resource);
          } else {
            resource.belongsTo("subscription").reload();
          }
          this.get("store").peekAll("account-setting").filterBy("isNew", true).invoke("rollbackAttributes");
          this.set("isSaving", false);
        }.bind(this)).catch(function (reason) {
          this.get("store").peekAll("account-setting").filterBy("isNew", true).filter(function (s) {
            return s.get("key") !== "account.billing_term";
          }).invoke("rollbackAttributes");
          this.set("isSaving", false);
          console.log("Errors", reason);
        }.bind(this));
      },
      clear: function () {
        this.get("model").rollbackAttributes();
      },
      toggleExtraPage: function () {
        this.set("extraPage", !this.get("extraPage"));
        return this.get("extraPage");
      },
      updateClientAdmins: function (users) {
        users.forEach(function (user) {
          if (user.get("isAdmin")) {
            user.set("role", this.get("clientAdminRole"));
            this.get("clientAdmins").pushObject(user);
            this.get("admins").removeObject(user);
          }
        }.bind(this));

        this.get("clientAdmins").forEach(function (clientAdmin) {
          if (!users.includes(clientAdmin)) {
            clientAdmin.set("role", this.get("adminRole"));
            this.get("clientAdmins").removeObject(clientAdmin);
            this.get("admins").pushObject(clientAdmin);
          }
        }.bind(this));
      },
      numberOfMultiRingDidsChanged() {
        if (this.get("model.settingsIncludedCallTouchPointsMultiRing.value") === "0") {
          this.set("model.settingsIncludedCallTouchPointsMultiRing.value", 1);
        }
      }
    },
    onKindChanged: Ember.observer("model.subscription.kind", function () {
      if (this.get("model.subscription.kind") === "trial") {
        this.set("model.settingsAccountBillingTerm.value", 1);
      }
    }),
    showInternalCheckbox: Ember.computed("session.currentUser.isMasterAdmin", function () {
      return this.get("session.currentUser.isMasterAdmin");
    }),
    subscriptionOptionTypes: Ember.computed("model.isNew", "model.subscription.kind", function () {
      let options = [{ value: "paid", text: "Paid" }];
      if (this.get("model.isNew")) {
        options.unshift({ value: "trial", text: "FT" });
      } else {
        options.push({ value: "cancelled", text: "Cancelled" });
        if (this.get("model.subscription.kind") === "trial") {
          options.unshift({ value: "trial", text: "FT" });
        }
      }
      return options;
    }),
    isModelTreeDirty: Ember.computed("model.hasDirtyAttributes", "model.settings.@each.hasDirtyAttributes", "model.subscription.planHasChanged", "model.subscription.hasDirtyAttributes", "parentAccountHasChanged", function () {
      return this.get("model.hasDirtyAttributes") || this.get("model.settings").isAny("hasDirtyAttributes", true) || this.get("model.subscription.planHasChanged") || this.get("model.subscription.hasDirtyAttributes") || this.get("parentAccountHasChanged");
    }),
    disableSubmit: Ember.computed("model", "model.validations.isValid", "model.subscription.validations.isValid", "isModelTreeDirty", "model.parentAccount", "clientAdminsHaveChanged", function () {
      let wizardConfirmation = !this.get("model.customerTypeConfirmed") && this.get("session.currentUser.account.id") == this.get("model.id");
      let clientAdminsHaveChanged = this.get("model.parentAccount") && this.get("clientAdminsHaveChanged");
      if (this.get("model.isNew")) {
        return !this.get("model.validations.isValid");
      } else {
        return (this.get("isSaving") || this.get("model.validations.isInvalid") || this.get("model.subscription.validations.isInvalid") || !this.get("isModelTreeDirty")) && !(wizardConfirmation && !this.get("model.validations.isInvalid")) && !clientAdminsHaveChanged;
      }
    })
  });
});