define("knowme-client/services/account", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    customersText: Ember.computed("session.account.industry", function () {
      try {
        return this.get("intl").t(`industries.${this.get("session.account.industry")}.name`, { count: 2 });
      } catch (err) {
        return this.get("intl").t(`industries.general.name`, { count: 2 });
      }
    }),
    customerText: Ember.computed("session.account.industry", function () {
      try {
        return this.get("intl").t(`industries.${this.get("session.account.industry")}.name`, { count: 1 });
      } catch (err) {
        return this.get("intl").t(`industries.general.name`, { count: 1 });
      }
    }),
    customersShortenText: Ember.computed("customersText", function () {
      if (this.get("customersText") === "Customers") {
        return "Cust.";
      } else {
        return this.get("customersText");
      }
    })
  });
});